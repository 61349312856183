<template>
  <main>
    <div v-if="offerings" class="container page-content">
      <div class="row pb-3">
        <div class="col">
          <h3>
            Offerings Watch List
            <i class="bi bi-bookmark-heart-fill btn-link"></i>
          </h3>
        </div>
        <div class="col">
          <router-link class="btn float-end" :to="{ name: 'Offerings' }">
            Show All Offerings <i class="bi bi-globe2 btn-link"></i>
          </router-link>
        </div>
      </div>
      <div class="row align-items-start">
        <div class="col-12 col-lg-9">
          <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-5">
            <div
              v-for="offering in offeringsWatched"
              :key="offering.id"
              class="col"
            >
              <OfferingSingle :offering="offering" />
            </div>
            <div class="text-center" v-if="offeringsWatched.length == 0">
              <p>No offerings exist with this tag.</p>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-3">
          <TagCloud :offerings="offerings" />
        </div>
      </div>
    </div>
  </main>
  <Footer v-if="offerings" />
</template>

<script>
import Footer from "@/components/navigation/Footer.vue";
import OfferingSingle from "@/components/offerings/OfferingSingle.vue";
import TagCloud from "@/components/tags/TagCloud.vue";
import getCollection from "@/composables/getCollection";
import { computed } from "@vue/runtime-core";
import getUser from "@/composables/getUser.js";

export default {
  components: { OfferingSingle, Footer, TagCloud },
  setup() {
    const { user } = getUser();
    const { error, documents: offerings } = getCollection(
      "offerings",
      [
        ["featured", ">=", 0],
        ["published", "==", true]
      ],
      ["featured", "desc"]
    );

    const offeringsWatched = computed(() => {
      return offerings.value.filter(
        offering =>
          offering.watchedBy && offering.watchedBy.includes(user.value.uid)
      );
    });

    return { error, offerings, offeringsWatched };
  }
};
</script>

<style scoped>
</style>